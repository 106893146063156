import * as nodeFetch from 'node-fetch';
import i18n from '../../i18n';

/* eslint-disable no-underscore-dangle */
export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const setHeaders = (): Headers => {
  const defaultHeaders: Headers | nodeFetch.Headers = typeof window !== 'undefined' ? new Headers() : new nodeFetch.Headers();
  const lang = i18n.language;
  defaultHeaders.set('Accept-Language', lang);
  defaultHeaders.set('Content-Type', 'application/json');
  defaultHeaders.set('Authorization', `Token ${process.env.PAYTEST_AUTH_TOKEN}`);
  return defaultHeaders as Headers;
};

export class APIRequest {
  constructor(headers: Headers = setHeaders()) {
    this._headers = headers;
  }

  private _headers: Headers;

  private _isPureRequest: boolean = false;

  public headers(headers: Headers): APIRequest {
    this._headers = {
      ...this._headers,
      ...headers,
    };
    return this;
  }

  public pure(): APIRequest {
    this._isPureRequest = true;
    return this;
  }

  public get(endpoint: string) {
    const request = this.request('GET');
    return this.makeRequest(endpoint, request);
  }

  public post<T>(endpoint: string, body: T) {
    const request = this.request('POST', body);
    return this.makeRequest(endpoint, request);
  }

  public put<T>(endpoint: string, body: T) {
    const request = this.request('PUT', body);
    return this.makeRequest(endpoint, request);
  }

  public delete<T>(endpoint: string, body: T) {
    const request = this.request('DELETE', body);
    return this.makeRequest(endpoint, request);
  }

  private request<T>(method: Method, body?: T): RequestInit {
    return {
      method,
      headers: this._headers,
      body: body && (typeof body !== 'string' && !(body instanceof FormData) ? JSON.stringify(body) : body),
    };
  }

  private makeRequest = (endpoint: string, request: RequestInit) => {
    return this._isPureRequest
      ? fetch(endpoint, request)
      : fetch(endpoint, request)
          .then(res => {
            return res.json();
          })
          .catch(error => error);
  };
}
