import { join } from 'path';
import i18n from '../../i18n';
import { CartItem } from '../common-interfaces';
import { Currency } from '@altermeliora/payform-types';
import { defaultLanguage } from '../../constants/locales';

export const encodeQueryData = (data: any) => {
  const ret = [];
  for (const d in data) {
    if (data[d] != null && data[d] !== '') {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join('&');
};

export const itemDiscountTotal = (item: CartItem): number => {
  if (item.discount) {
    return (
      item.discount.totals.find(total => {
        return total.currency === Currency.euro;
      }).total / item.quantity || 0
    );
  }
  return 0;
};

export const toDecimals = (number: number, decimals = 2): number => {
  const power = 10 ** decimals;
  return Math.round(number * power) / power;
};

export const pathTo = (url: string): string => {
  const langPathPrefix: string = i18n.language === defaultLanguage ? '' : i18n.language;
  return join('/', langPathPrefix, url);
};
