import { AnyAction } from 'redux';

// Action names

export enum ActionTypes {
  SET_GEOCODE_STATUS = 'geo/SET_GEOCODE_STATUS',
  GET_GEOCODE = 'geo/GET_GEO_CODE',
  SET_GEOCODE = 'geo/SET_GEO_CODE',
  CLEAR_GEOCODE = 'geo/CLEAR_GEOCODE',
  SET_COUNTRY_CODE = 'geo/SET_COUNTRY_CODE',
}

// Action types

export interface SetGeocodeStatusAction extends AnyAction {
  type: ActionTypes.SET_GEOCODE_STATUS;
  payload: {
    loading: boolean;
    error?: string;
  };
}

export interface GetGeocodeAction extends AnyAction {
  type: ActionTypes.GET_GEOCODE;
  payload: google.maps.GeocoderRequest;
}

export interface SetGeocodeAction extends AnyAction {
  type: ActionTypes.SET_GEOCODE;
  payload: google.maps.GeocoderResult;
}

export interface ClearGeocodeAction extends AnyAction {
  type: ActionTypes.CLEAR_GEOCODE;
}

export interface SetCountryCodeAction extends AnyAction {
  type: ActionTypes.SET_COUNTRY_CODE;
  payload: string;
}

// Action creators

export const setGeocodeStatusActionCreator = (payload: { loading: boolean; error?: string }): SetGeocodeStatusAction => {
  return {
    payload,
    type: ActionTypes.SET_GEOCODE_STATUS,
  };
};

export const getGeocodeActionCreator = (payload: google.maps.GeocoderRequest): GetGeocodeAction => {
  return {
    payload,
    type: ActionTypes.GET_GEOCODE,
  };
};

export const setGeocodeActionCreator = (payload: google.maps.GeocoderResult): SetGeocodeAction => {
  return {
    payload,
    type: ActionTypes.SET_GEOCODE,
  };
};

export const clearGeocodeActionCreator = (): ClearGeocodeAction => {
  return {
    type: ActionTypes.CLEAR_GEOCODE,
  };
};

export const setCountryCodeActionCreator = (payload: string): SetCountryCodeAction => {
  return {
    payload,
    type: ActionTypes.SET_COUNTRY_CODE,
  };
};

export type Action = SetGeocodeStatusAction | GetGeocodeAction | SetGeocodeAction | ClearGeocodeAction | SetCountryCodeAction;
