import { Action, ActionTypes } from './cart.actions';
import { CartItem } from '../../lib/common-interfaces';
import { addToCart, removeFromCart, applyDiscounts } from '../utils';

export interface CartModel {
  status: {
    loading: boolean;
    clickedId?: number | null | undefined;
    error?: string;
  };
  cart: CartItem[];
}

export const cartInitialState: CartModel = {
  status: {
    loading: false,
  },
  cart: [],
};

export const reducer = (state = cartInitialState, action: Action): CartModel => {
  switch (action.type) {
    case ActionTypes.SET_CART_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload,
        },
      };
    case ActionTypes.ADD_TO_CART:
      return {
        ...state,
        cart: addToCart(state.cart, action.payload),
      };
    case ActionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cart: removeFromCart(state.cart, action.payload),
      };
    case ActionTypes.CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    case ActionTypes.APPLY_DISCOUNTS:
      return {
        ...state,
        cart: applyDiscounts(state.cart, action.payload),
      };
    default:
      return state;
  }
};
