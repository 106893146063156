import * as React from 'react';
import { call, put, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import { navigate } from '@reach/router';
import { notification, Button } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import i18n from '../../i18n';
import * as cartActions from './cart.actions';
import { checkPromocodeRequest } from '../../api/discount';
import { pathTo } from '../../lib/utils';

export function* addToCartSaga(action: cartActions.AddToCartSagaAction) {
  const { product, showNotification } = action.payload;
  const btn: React.ReactNode = React.createElement(
    Button,
    {
      type: 'primary',
      onClick: () => {
        notification.close('addNotification');
        navigate(pathTo('cart'));
      },
    },
    `${i18n.t('shell:cart.toCartBtn')}`
  );
  const notificationOptions: ArgsProps = {
    placement: 'topRight',
    message: `${product.product.title}${i18n.t('shell:cart.messages.was')}${
      product.quantity > 0 ? i18n.t('shell:cart.messages.added') : i18n.t('shell:cart.messages.removed')
    }`,
    btn,
    key: 'addNotification',
    duration: 3,
  };
  yield put(cartActions.SetCartStatusActionCreator({ loading: true, clickedId: product.product.idProduct }));
  yield put(cartActions.AddToCartActionCreator(product));
  yield put(cartActions.SetCartStatusActionCreator({ loading: false }));
  if (showNotification) yield call(notification.success, notificationOptions);
}

export function* removeFromCartSaga(action: cartActions.RemoveFromCartSagaAction) {
  const notificationOptions: ArgsProps = {
    placement: 'topRight',
    message: i18n.t('shell:cart.messages.removedFull'),
  };
  yield put(cartActions.SetCartStatusActionCreator({ loading: true }));
  yield put(cartActions.RemoveFromCartActionCreator(action.payload));
  yield put(cartActions.SetCartStatusActionCreator({ loading: false }));
  if (action.payload.showNotification) yield call(notification.info, notificationOptions);
}

export function* checkPromocodeSaga(action: cartActions.CheckPromocodeAction) {
  yield put(cartActions.SetCartStatusActionCreator({ loading: true }));
  const response = yield call(checkPromocodeRequest, action.payload);
  if ('error' in response && 'message' in response && 'statusCode' in response && response.statusCode === 404) {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:discount.promocode.notFound'),
      duration: 3,
    };
    yield call(notification.error, notificationOptions);
  } else if ('statusCode' in response && response.statusCode === 400) {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:discount.promocode.date'),
      duration: 3,
    };
    yield call(notification.error, notificationOptions);
  } else {
    yield put(cartActions.ApplyDiscountsActionCreator(response));
  }
  yield put(cartActions.SetCartStatusActionCreator({ loading: false }));
}

export default [
  takeEvery(cartActions.ActionTypes.ADD_TO_CART_SAGA, addToCartSaga),
  takeLatest(cartActions.ActionTypes.REMOVE_FROM_CART_SAGA, removeFromCartSaga),
  takeLatest(cartActions.ActionTypes.CHECK_PROMOCODE, checkPromocodeSaga),
];
