import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { CartModel, reducer as cart, cartSagas } from './cart';
import { OrderModel, reducer as order, orderSagas } from './order';
import { GeocodeModel, reducer as geocode, geoSagas } from './geo';
import { ShippingModel, reducer as shipping, shippingSagas } from './shipping';
import { loadState, saveState } from './localStorage';

const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();

export const rootReducer = combineReducers({
  cart,
  order,
  geocode,
  shipping,
});

export interface RootModel {
  cart: CartModel;
  // user: UserModel;
  order: OrderModel;
  geocode: GeocodeModel;
  shipping: ShippingModel;
}

function* rootSaga() {
  yield all([
    ...cartSagas,
    // ...userSagas,
    ...orderSagas,
    ...geoSagas,
    ...shippingSagas,
  ]);
}

export default function() {
  const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  store.subscribe(() => {
    saveState({
      cart: store.getState().cart,
      order: store.getState().order,
      geocode: store.getState().geocode,
      // shipping: store.getState().shipping,
    });
  });
  return store;
}
