import { Action, ActionTypes } from './geo.actions';
import { Geocode } from '../../lib/common-interfaces';

export interface GeocodeModel {
  status: {
    loading: boolean;
    error?: string;
  };
  geocode?: google.maps.GeocoderResult;
  countryCode?: string;
}

export const geocodeInitialState: GeocodeModel = {
  status: {
    loading: false,
  },
};

export const reducer = (state = geocodeInitialState, action: Action): GeocodeModel => {
  switch (action.type) {
    case ActionTypes.SET_GEOCODE_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload,
        },
      };
    case ActionTypes.SET_GEOCODE:
      return {
        ...state,
        geocode: action.payload,
      };
    case ActionTypes.CLEAR_GEOCODE:
      return {
        ...state,
        geocode: undefined,
      };
    case ActionTypes.SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      };
    default:
      return state;
  }
};
