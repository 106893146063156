import { APIRequest } from './api-service/api-request';
import { encodeQueryData } from '../lib/utils';
import { PayformOrder, OrderStatusParams, OrderWithOptions, OrderKeyParams } from '../lib/common-interfaces';

export const placeOrderRequest = (order: PayformOrder) => {
  const request = new APIRequest();
  return request.post(process.env.CHECKOUT_API_URL as string, order);
};

export const getOrderStatusRequest = (params: OrderStatusParams) => {
  let paramsString = '';
  if (params) paramsString = `?${encodeQueryData(params)}`;
  const request = new APIRequest();
  return request.get(`${process.env.CHECKOUT_API_URL}${paramsString}`);
};

export const saveOrderRequest = (order: OrderWithOptions) => {
  const request = new APIRequest();
  return request.post(`${process.env.CHECKOUT_API_URL}/order`, order);
};

export const readOrderRequest = (params: OrderKeyParams): Promise<PayformOrder> => {
  let paramsString = '';
  if (params) paramsString = `?${encodeQueryData(params)}`;
  const request = new APIRequest();
  return request.get(`${process.env.CHECKOUT_API_URL}/order${paramsString}`);
};

export const deleteOrderRequest = (params: OrderKeyParams) => {
  const request = new APIRequest();
  return request.delete(`${process.env.CHECKOUT_API_URL}/order`, params);
};
