import { call, put, takeLatest } from 'redux-saga/effects';
import { navigate } from '@reach/router';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import * as orderActions from './order.actions';
import i18n from '../../i18n';
import { PayformOrder, OrderWithOptions } from '../../lib/common-interfaces';
import { placeOrderRequest, saveOrderRequest, readOrderRequest, deleteOrderRequest, getOrderStatusRequest } from '../../api/order';
import { convertOrderWithOptions, convertOrderForPayment } from '../utils';
import { encodeQueryData } from '../../lib/utils';
import { defaultLanguage } from '../../constants/locales';

export function* submitOrderSaga(action: orderActions.SubmitOrderAction) {
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: true }));

  const APIorder: PayformOrder = yield convertOrderForPayment(action.payload);
  const response = yield call(placeOrderRequest, APIorder);

  if (response) {
    yield put(orderActions.CreateOrderActionCreator({ ...action.payload, _id: response.invoice }));
    yield call(navigate, response.payment_url);
  } else {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:order.messages.error'),
    };
    yield call(notification.error, notificationOptions);
  }
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: false }));
}

export function* saveOrderSaga(action: orderActions.SaveOrderAction) {
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: true }));
  const APIorder: OrderWithOptions = yield convertOrderWithOptions(action.payload);
  const response = yield call(saveOrderRequest, APIorder);
  if (response) {
    if ('key' in response) {
      const paramsString = `?${encodeQueryData(response)}`;
      const langPathPrefix: string = i18n.language === defaultLanguage ? '/' : `/${i18n.language}/`;
      const link = `${window.location.origin}${langPathPrefix}checkout/${paramsString}`;
      yield navigator.clipboard.writeText(link);
      yield put(orderActions.SetLinkActionCreator(link));
      const notificationOptions: ArgsProps = {
        placement: 'topRight',
        message: `${i18n.t('shell:order.messages.link')}`,
      };
      yield call(notification.info, notificationOptions);
    }
    yield put(orderActions.CreateOrderActionCreator({ ...action.payload, key: response.key }));
  } else {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:order.messages.error'),
    };
    yield call(notification.error, notificationOptions);
  }
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: false }));
}

export function* readOrderSaga(action: orderActions.ReadOrderAction) {
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: true }));
  const response = yield call(readOrderRequest, action.payload);
  if ('order' in response) {
    yield put(orderActions.SetSavedOrderActionCreator(response.order));
  } else if ('success' in response && 'message' in response) {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: response.message,
    };
    yield call(notification.error, notificationOptions);
  } else if ('error' in response) {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: response.error,
    };
    yield call(notification.error, notificationOptions);
  } else {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:order.messages.error'),
    };
    yield call(notification.error, notificationOptions);
  }
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: false }));
}

export function* deleteOrderSaga(action: orderActions.DeleteOrderAction) {
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: true }));
  const response = yield call(deleteOrderRequest, action.payload);
  if (!response) {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:order.messages.error'),
    };
    yield call(notification.error, notificationOptions);
  }
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: false }));
}

export function* getOrderStatusSaga(action: orderActions.GetOrderStatusAction) {
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: true }));
  const response = yield call(getOrderStatusRequest, { invoice: action.payload });
  if (response) {
    yield put(orderActions.SetOrderStatusActionCreator(response));
  }
  yield put(orderActions.SetOrderProcessStatusActionCreator({ loading: false }));
}

export default [
  takeLatest(orderActions.ActionTypes.SUBMIT_ORDER, submitOrderSaga),
  takeLatest(orderActions.ActionTypes.SAVE_ORDER, saveOrderSaga),
  takeLatest(orderActions.ActionTypes.READ_ORDER, readOrderSaga),
  takeLatest(orderActions.ActionTypes.DELETE_ORDER, deleteOrderSaga),
  takeLatest(orderActions.ActionTypes.GET_ORDER_STATUS, getOrderStatusSaga),
];
