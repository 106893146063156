import { call, put, takeLatest } from 'redux-saga/effects';
import * as geocodeActions from './geo.actions';
import { getGeocodeRequest } from '../../api/geocode';
import { extractCountry } from '../utils';

export function* getGeocodeSaga(action: geocodeActions.GetGeocodeAction) {
  yield put(geocodeActions.setGeocodeStatusActionCreator({ loading: true }));
  const response = yield call(getGeocodeRequest, action.payload);
  if (response && 'results' in response && 'status' in response && response.status === 'OK') {
    yield put(geocodeActions.setGeocodeActionCreator(response));
    const countryCode = yield call(extractCountry, response);
    if (countryCode) yield put(geocodeActions.setCountryCodeActionCreator(countryCode));
  }
  yield put(geocodeActions.setGeocodeStatusActionCreator({ loading: false }));
}

export default [takeLatest(geocodeActions.ActionTypes.GET_GEOCODE, getGeocodeSaga)];
