/* eslint-disable @typescript-eslint/no-var-requires */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const langs = require('../constants/locales');
const shellEN = require('./translations/en/shell.json');
const shellRU = require('./translations/ru/shell.json');
const checkoutEN = require('./translations/en/checkout.json');
const checkoutRU = require('./translations/ru/checkout.json');
const statusEN = require('./translations/en/status.json');
const statusRU = require('./translations/ru/status.json');

const { languages, defaultLanguage } = langs;

const resources = {
  en: {
    shell: shellEN,
    checkout: checkoutEN,
    status: statusEN,
  },
  ru: {
    shell: shellRU,
    checkout: checkoutRU,
    status: statusRU,
  },
};

const initializeLang = (): string | undefined => {
  if (typeof window !== 'undefined') {
    const localLang = localStorage.getItem('lang');
    let lang = localLang || navigator.language.split('-')[0];
    const isSupportedLang = languages.find((l: string) => lang === l);
    if (!isSupportedLang) lang = defaultLanguage;
    return lang;
  }
  return defaultLanguage;
};

const initLang = initializeLang();

i18n.use(initReactI18next).init({
  resources,
  lng: initLang,
  fallbackLng: defaultLanguage,
});

export default i18n;
