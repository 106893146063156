import { AnyAction } from 'redux';
import { DiscountCalculateWithCartRequestDto } from '@altermeliora/payform-types'
import { CartItem, Option, ColorOption, ItemDiscount } from '../../lib/common-interfaces';

// Action names

export enum ActionTypes {
  SET_CART_STATUS = 'cart/SET_CART_STATUS',
  ADD_TO_CART = 'cart/ADD_TO_CART',
  REMOVE_FROM_CART = 'cart/REMOVE_FROM_CART',
  ADD_TO_CART_SAGA = 'cart/ADD_TO_CART_SAGA',
  REMOVE_FROM_CART_SAGA = 'cart/REMOVE_FROM_CART_SAGA',
  CLEAR_CART = 'cart/CLEAR_CART',
  APPLY_DISCOUNTS = 'cart/APPLY_DISCOUNTS',
  CHECK_PROMOCODE = 'cart/CHECK_PROMOCODE',
}

// Action types

export interface SetCartStatusAction extends AnyAction {
  type: ActionTypes.SET_CART_STATUS;
  payload: {
    loading: boolean;
    clickedId?: number | null | undefined;
    error?: string;
  };
}

export interface AddToCartAction extends AnyAction {
  type: ActionTypes.ADD_TO_CART;
  payload: CartItem;
}

export interface RemoveFromCartAction extends AnyAction {
  type: ActionTypes.REMOVE_FROM_CART;
  payload: {
    productId: number;
    quantity?: number;
    option?: Option;
    colorOption?: ColorOption;
  };
}

export interface AddToCartSagaAction extends AnyAction {
  type: ActionTypes.ADD_TO_CART_SAGA;
  payload: { product: CartItem; showNotification?: boolean };
}

export interface RemoveFromCartSagaAction extends AnyAction {
  type: ActionTypes.REMOVE_FROM_CART_SAGA;
  payload: {
    productId: number;
    quantity?: number;
    option?: Option;
    showNotification?: boolean;
  };
}

export interface ClearCartAction extends AnyAction {
  type: ActionTypes.CLEAR_CART;
}

export interface ApplyDiscountsAction extends AnyAction {
  type: ActionTypes.APPLY_DISCOUNTS;
  payload: ItemDiscount[];
}

export interface CheckPromocodeAction extends AnyAction {
  type: ActionTypes.CHECK_PROMOCODE;
  payload: DiscountCalculateWithCartRequestDto;
}

// Action creators

export const SetCartStatusActionCreator = (payload: {
  loading: boolean;
  clickedId?: number | null | undefined;
  error?: string;
}): SetCartStatusAction => {
  return {
    payload,
    type: ActionTypes.SET_CART_STATUS,
  };
};

export const AddToCartActionCreator = (payload: CartItem): AddToCartAction => {
  return {
    payload,
    type: ActionTypes.ADD_TO_CART,
  };
};

export const RemoveFromCartActionCreator = (payload: { productId: number; quantity?: number; option?: Option }): RemoveFromCartAction => {
  return {
    payload,
    type: ActionTypes.REMOVE_FROM_CART,
  };
};

export const AddToCartSagaActionCreator = (payload: { product: CartItem; showNotification?: boolean }): AddToCartSagaAction => {
  return {
    payload,
    type: ActionTypes.ADD_TO_CART_SAGA,
  };
};

export const RemoveFromCartSagaActionCreator = (payload: {
  productId: number;
  quantity?: number;
  option?: Option;
  colorOption?: ColorOption;
  showNotification?: boolean;
}): RemoveFromCartSagaAction => {
  return {
    payload,
    type: ActionTypes.REMOVE_FROM_CART_SAGA,
  };
};

export const ClearCartActionCreator = (): ClearCartAction => {
  return {
    type: ActionTypes.CLEAR_CART,
  };
};

export const ApplyDiscountsActionCreator = (payload: ItemDiscount[]): ApplyDiscountsAction => {
  return {
    payload,
    type: ActionTypes.APPLY_DISCOUNTS,
  };
};

export const CheckPromocodeActionCreator = (payload: DiscountCalculateWithCartRequestDto): CheckPromocodeAction => {
  return {
    payload,
    type: ActionTypes.CHECK_PROMOCODE,
  };
};

export type Action =
  | SetCartStatusAction
  | AddToCartAction
  | RemoveFromCartAction
  | AddToCartSagaAction
  | RemoveFromCartSagaAction
  | ClearCartAction
  | ApplyDiscountsAction
  | CheckPromocodeAction;
